<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind">{{ list.RIKindDesc }}</div>
      <div v-if="list.ThematicUrl" class="essayImg">
        <img :src="list.ThematicUrl" alt="" />
      </div>
      <p class="essayContent" v-html="list.Content"></p>
    </div>
  </div>
</template>
<script>
import { WeGetRealInfo } from "@/api/RealInfo";
export default {
  data() {
    return {
      list: {},
    };
  },
  methods: {
    // 获取资讯详情
    getRealInfo() {
      WeGetRealInfo({ riId: this.$route.params.Id }).then((res) => {
        this.list = res.data.data;
        console.log(this.list);
        // this.list.Content = this.list.Content.replace(/<.*?>/ig,"")
        // console.log(this.list.Content);
      });
    },
  },
  mounted() {
    this.getRealInfo();
  },
};
</script>
<style>
</style>